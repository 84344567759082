import { ITipTapRichText } from "../types/TextItem";

export const EMPTY_RICH_TEXT: ITipTapRichText = {
  type: "doc",
  content: [
    {
      type: "paragraph",
    },
  ],
};

export const IS_SAFARI =
  typeof window !== "undefined" && navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome");

export const TEXT_SELECTION_PARAM = "selectedTextItems";
export const DESIGN_PREVIEW_PARAM = "designPreview";
