import * as RTooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import React, { memo, useCallback } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { useNativeProps } from "../../useNativeProps";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;

  /**
   * The content of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content: string | React.ReactNode;

  /**
   * Additional styles to apply to the content of the tooltip.
   */
  contentClassName?: string;

  /**
   * Additional styles to apply to the trigger element of the tooltip.
   */
  triggerClassName?: string;

  /**
   * The icon to be displayed in the tooltip. Should be an icon as React node, e.g. <Flag />.
   */
  icon?: React.ReactNode;

  /**
   * The header of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  header?: string | React.ReactNode;

  /**
   * The delay in milliseconds before the tooltip appears when hovering over the triggering element.
   * Defaults to 200.
   */
  hoverDelay?: number;
  side?: "top" | "right" | "bottom" | "left";
  size?: "base" | "sm";
  disabled?: boolean;
  /**
   * Align the tooltip against its trigger element.
   */
  align?: "center" | "start" | "end";
  /**
   * Align the text within the tooltip.
   */
  textAlign?: "left" | "center" | "right" | "justify";
  /**
   * Style variants.
   */
  type?: "default" | "invert";

  /**
   * Force the content to be rendered as text, even if it's a React node.
   */
  forceText?: boolean;

  /**
   * If true, the tooltip will remain open when the user clicks on the trigger.
   * Use this for things like click to copy.
   */
  stayOpenOnTriggerClick?: boolean;

  // If you need to pass any of the Radix UI Tooltip props, you can do so here.
  // See https://www.radix-ui.com/primitives/docs/components/tooltip
  rTooltipProps?: RTooltip.TooltipProps;
  rTriggerProps?: RTooltip.TooltipTriggerProps;
  rContentProps?: RTooltip.TooltipContentProps;
}

/**
 * Displays content related to an element when it receives hover or focus.
 * Usage: wrap the triggering element with `<Tooltip>`, and pass in your desired content in the `content`, `header`, and `icon` props.
 */
export const Tooltip = memo(function Tooltip(props: IProps) {
  const delay = props.hoverDelay ?? 300;
  const side = props.side ?? "top";
  const type = props.type ?? "default";
  const align = props.align ?? "center";

  const onTriggerClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (props.stayOpenOnTriggerClick) {
        e.preventDefault();
      }
      if (props.rTriggerProps?.onClick) {
        props.rTriggerProps.onClick(e);
      }
    },
    [props.rTriggerProps, props.stayOpenOnTriggerClick]
  );

  const onPointerDownOutsideContent = useCallback(
    (e: CustomEvent<{ originalEvent: PointerEvent }>) => {
      if (props.stayOpenOnTriggerClick) {
        e.preventDefault();
      }
      if (props.rContentProps?.onPointerDownOutside) {
        props.rContentProps.onPointerDownOutside(e);
      }
    },
    [props.rContentProps, props.stayOpenOnTriggerClick]
  );

  return (
    <RTooltip.Provider delayDuration={delay}>
      <RTooltip.Root open={props.disabled ? false : undefined} {...props.rTooltipProps}>
        <RTooltip.Trigger disabled={props.disabled} asChild {...props.rTriggerProps} onClick={onTriggerClick}>
          <div className={classNames(style.tooltipTrigger, props.triggerClassName)}>{props.children}</div>
        </RTooltip.Trigger>
        <RTooltip.Portal>
          <RTooltip.Content
            side={side}
            align={align}
            sideOffset={5}
            asChild
            {...props.rContentProps}
            onPointerDownOutside={onPointerDownOutsideContent}
          >
            {props.content && (
              <TooltipContent
                className={props.contentClassName}
                textAlign={props.textAlign}
                icon={props.icon}
                header={props.header}
                content={props.content}
                arrow={<RTooltip.Arrow className={classNames(style.tooltipArrow, { [style[`type-${type}`]]: true })} />}
                size={props.size}
                type={props.type}
                forceText={props.forceText}
              />
            )}
          </RTooltip.Content>
        </RTooltip.Portal>
      </RTooltip.Root>
    </RTooltip.Provider>
  );
});

interface IBodyProps {
  /**
   * Additional styles to apply to the content of the tooltip.
   */
  className?: string;

  /**
   * The icon to be displayed in the tooltip. Should be an icon as React node, e.g. <Flag />.
   */
  icon?: React.ReactNode;

  /**
   * The header of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  header?: string | React.ReactNode;

  /**
   * The content of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content: string | React.ReactNode;
  arrow?: React.ReactNode;
  size?: "base" | "sm";

  /**
   * Style variants.
   */
  type?: "default" | "invert";
  /**
   * Force the content to be rendered as text, even if it's a React node.
   */
  forceText?: boolean;
  /**
   * Align the text within the tooltip.
   */
  textAlign?: "left" | "center" | "right" | "justify";
}
export const TooltipContent = memo(
  React.forwardRef<HTMLDivElement, IBodyProps>((props, ref) => {
    const nativeProps = useNativeProps(props, {
      icon: true,
      header: true,
      size: true,
      arrow: true,
      type: true,
      forceText: true,
      textAlign: true,
    });

    const size = props.size ?? "base";
    const type = props.type ?? "default";

    // Base size tooltip gets a small icon; small size gets an xs icon.
    const iconSize = size === "sm" ? "xs" : "small";

    return (
      <div
        {...nativeProps}
        ref={ref}
        className={classNames(style.tooltipContent, {
          [style.small]: size === "sm",
          [style[`type-${type}`]]: true,
        })}
      >
        {props.arrow && props.arrow}
        {props.icon && <Icon Icon={props.icon} size={iconSize} />}
        <div className={classNames(style.textWrapper)}>
          {props.header && (
            <>
              {typeof props.header === "string" ? <Text className={style.header}>{props.header}</Text> : props.header}
            </>
          )}
          {props.content && (
            <>
              {typeof props.content === "string" || props.forceText ? (
                <Text className={style.content} textAlign={props.textAlign}>
                  {props.content}
                </Text>
              ) : (
                props.content
              )}
            </>
          )}
        </div>
      </div>
    );
  })
);

export default Tooltip;
