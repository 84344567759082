import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;

  /**
   * Presets for badge style.
   */
  type?: "default" | "code" | "outline" | "action-outline" | "subtle" | "minimal";

  /**
   * Options for border radii.
   */
  borderRadius?: "xs" | "sm" | "md" | "default" | "lg";

  /**
   * Color of the badge. Defaults to black.
   */
  color?: "black" | "transparent" | "blue" | "blue-inverted" | "neutral" | "subtle" | "purple";

  /**
   * Size of the badge. Defaults to md.
   */
  size?: "micro" | "xs" | "sm" | "md" | "lg";

  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function Badge(props: IProps) {
  const color = props.color ?? "black";
  const type = props.type ?? "default";
  const size = props.size ?? "md";
  const borderRadius = props.borderRadius ?? "default";

  return (
    <div
      style={props.style}
      className={classNames(style.BadgeWrapper, props.className, {
        [style[`color-${color}`]]: true,
        [style[`border-radius-${borderRadius}`]]: true,
        [style[`type-${type}`]]: true,
        [style[`size-${size}`]]: size,
      })}
      data-testid="badge"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export default Badge;
