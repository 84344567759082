import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import AddToLibraryIcon from "../../atoms/Icon/icons/AddToLibraryIcon";
import LibraryComponentLinkIcon from "../../atoms/Icon/icons/LibraryComponentLinkIcon";
import Text from "../../atoms/Text";
import Tooltip from "../../molecules/Tooltip";
import LibraryComingSoon, { ILibraryComingSoonProps } from "../LibraryComingSoon";
import style from "./index.module.css";

interface ILibrarySectionProps extends Omit<ILibraryComingSoonProps, "layout"> {
  useComingSoon: boolean;
  className?: string;
  dividerAbove?: boolean;
  dividerBelow?: boolean;
  hasLinkSuggestions?: boolean;
  onClickPublish?: () => void;
  onClickLink?: () => void;
}

export function LibrarySection({
  className,
  dividerAbove,
  dividerBelow,
  onClickPublish,
  onClickLink,
  useComingSoon,
  hasLinkSuggestions = false,
  ...libraryComingSoonProps
}: ILibrarySectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickPublish = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    onClickPublish?.();
  }, [onClickPublish]);

  const handleClickLink = useCallback(() => {
    if (useComingSoon) {
      setIsOpen((isOpen) => !isOpen);
    }
    onClickLink?.();
  }, [onClickLink, useComingSoon]);

  return (
    <div
      className={classNames(style.container, className, {
        [style.borderTop]: dividerAbove,
        [style.borderBottom]: dividerBelow,
      })}
      data-testid="library-section"
    >
      <div className={style.header}>
        <div>
          <Text size="small" color="primary" weight="strong">
            Library
          </Text>
        </div>
        <div className={style.buttonsContainer}>
          <Tooltip content="Publish to library..." side="left" type="invert">
            <Button level="subtle" type="icon" onClick={handleClickPublish}>
              <Icon color="secondary" Icon={<AddToLibraryIcon />} className={style.libraryIcon} />
            </Button>
          </Tooltip>
          <Tooltip content="Link to library component..." side="left" type="invert">
            <Button
              className={classNames(style.linkButton, { [style.hasSuggestions]: hasLinkSuggestions })}
              level="subtle"
              type="icon"
              onClick={handleClickLink}
            >
              <Icon color={hasLinkSuggestions ? "purple-action" : "secondary"} Icon={<LibraryComponentLinkIcon />} />
              {hasLinkSuggestions && <div className={style.dot} />}
            </Button>
          </Tooltip>
        </div>
      </div>
      {isOpen && <LibraryComingSoon layout="horizontal" {...libraryComingSoonProps} />}
    </div>
  );
}

export default LibrarySection;
