import { ITextItem } from "@shared/types/TextItem";
import { buildDittoClient } from "../../client/buildDittoClient";
import batchedAsyncAtomFamily from "./batchedAsyncAtomFamily";

export function createTextItemsStore(client: ReturnType<typeof buildDittoClient>) {
  /**
   * The source of truth for all text items.
   */
  const { familyAtom: textItemFamilyAtom, resetAtom: resetTextItemFamilyAtomActionAtom } =
    batchedAsyncAtomFamily<ITextItem>({
      asyncFetchRequest: async (get, ids) => {
        const response = await client.textItem.getTextItems({
          ids,
        });

        return response.textItems;
      },
      getId: (item) => item._id,
      debugPrefix: "Text Item",
    });

  return {
    textItemFamilyAtom,
    resetTextItemFamilyAtomActionAtom,
  };
}
