import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import useSegment from "@/hooks/useSegment";
import { instanceDataForSelectedComponentAtom, libraryInstancesPanelVirtualizerAtom } from "@/stores/Library";
import Text from "@ds/atoms/Text";
import FrameIcon from "@ds/icons/FrameIcon";
import NavItem from "@ds/molecules/NavItem";
import Scrollbar from "@ds/molecules/Scrollbar";
import { InstanceCountBadge } from "@ds/molecules/TextItem/index.lib";
import LayersIcon from "@mui/icons-material/Layers";
import { NS_BETA_URL_PATH_NAME } from "@shared/common/constants";
import { DESIGN_PREVIEW_PARAM, TEXT_SELECTION_PARAM } from "@shared/frontend/constants";
import { FRAME_IN_LIBRARY_INSTANCES_TAB_CLICKED } from "@shared/segment-event-names";
import { useAtomValue } from "jotai";
import React, { Suspense } from "react";
import LibraryComponentInstancesBanner from "../LibraryComponentInstancesBanner";
import style from "./style.module.css";

function LibraryEditInstancesPanel() {
  const libraryInstancesPanelListItems = useAtomValue(instanceDataForSelectedComponentAtom);
  const redirectToNSPage = useRedirectToNSPage();

  const { track } = useSegment();

  if (libraryInstancesPanelListItems.length === 0) {
    return (
      <div className={style.scrollArea}>
        <div className={style.emptyContainer}>
          <Text color="secondary" textAlign="center">
            No instances found. Once you use a component in a project, it will appear here.
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className={style.scrollArea}>
      <Suspense fallback={null}>
        <LibraryComponentInstancesBanner />
      </Suspense>
      <Scrollbar>
        {libraryInstancesPanelListItems.map((item) => {
          return (
            <NavItem
              key={item.project._id}
              id={item.project._id}
              type="group"
              label={item.project.name}
              trailingUI={
                <InstanceCountBadge
                  isComponent
                  tooltipContent={`Linked to ${item.textItems.length} text item${
                    item.textItems.length === 1 ? "" : "s"
                  } in project`}
                  instanceCount={item.textItems.length}
                  onClick={() => {
                    redirectToNSPage(
                      `/${NS_BETA_URL_PATH_NAME}/${item.project._id}`,
                      new URLSearchParams({
                        [TEXT_SELECTION_PARAM]: item.textItems.map((textItem) => textItem._id).join(","),
                      })
                    );
                  }}
                />
              }
              toggleOnGroupHeaderClick
              isCollapsedAtom={item.collapsedAtom}
              groupChildrenProps={item.framesAndPages.map((frameOrPage) => {
                if (frameOrPage.type === "empty") {
                  return {
                    id: frameOrPage.id,
                    type: "item",
                    label: "No instances in project connected to design",
                    labelOptions: {
                      color: "placeholder",
                    },
                  };
                } else if (frameOrPage.type === "page") {
                  return {
                    id: frameOrPage.pageId,
                    type: "item",
                    label: frameOrPage.pageName,
                    icon: <LayersIcon className={style.figmaGroupIcon} />,
                    trailingUI: (
                      <InstanceCountBadge
                        tooltipContent={`Linked to ${frameOrPage.nodeIds.length} text layer${
                          frameOrPage.nodeIds.length === 1 ? "" : "s"
                        } in this page`}
                        instanceCount={frameOrPage.nodeIds.length}
                      />
                    ),
                    onClick: () => {
                      redirectToNSPage(
                        `/${NS_BETA_URL_PATH_NAME}/${item.project._id}`,
                        new URLSearchParams({
                          [TEXT_SELECTION_PARAM]: item.textItems.map((textItem) => textItem._id).join(","),
                        })
                      );
                    },
                  };
                } else {
                  return {
                    id: frameOrPage.frameId,
                    type: "item",
                    label: frameOrPage.frameName,
                    icon: <FrameIcon className={style.figmaGroupIcon} />,
                    trailingUI: (
                      <InstanceCountBadge
                        tooltipContent={`Linked to ${frameOrPage.nodeIds.length} text layer${
                          frameOrPage.nodeIds.length === 1 ? "" : "s"
                        } on this frame`}
                        instanceCount={frameOrPage.nodeIds.length}
                      />
                    ),
                    onClick: () => {
                      track({
                        event: FRAME_IN_LIBRARY_INSTANCES_TAB_CLICKED,
                        properties: {
                          version: "NS",
                          application: "web_app",
                        },
                      });

                      redirectToNSPage(
                        `/${NS_BETA_URL_PATH_NAME}/${item.project._id}`,
                        new URLSearchParams({
                          [TEXT_SELECTION_PARAM]: Array.from(frameOrPage.textItemIds).join(","),
                          [DESIGN_PREVIEW_PARAM]: "true",
                        })
                      );
                    },
                  };
                }
              })}
              virtualizerAtom={libraryInstancesPanelVirtualizerAtom}
            />
          );
        })}
      </Scrollbar>
    </div>
  );
}

export default LibraryEditInstancesPanel;
