import useSegment from "@/hooks/useSegment";
import { useWorkspace } from "@/store/workspaceContext";
import {
  figmaSyncActionAtom,
  figmaSyncErrorMessageAtom,
  figmaSyncMessageAtom,
  isFigmaSyncingAtom,
} from "@/stores/FigmaSync";
import {
  deferredProjectNameAtom,
  editableProjectNameAtom,
  projectFigmaFileLinkAtom,
  projectFolderAtom,
  resetEditableProjectNameAtom,
  saveProjectNameActionAtom,
} from "@/stores/Project";
import BetaButton from "@ds/atoms/BetaButton";
import Button from "@ds/atoms/Button";
import Breadcrumbs from "@ds/molecules/Breadcrumbs";
import DropdownMenu, { DropdownMenuItemType } from "@ds/molecules/DropdownMenu";
import InlineEditableName from "@ds/molecules/InlineEditableName";
import { Tooltip } from "@ds/molecules/Tooltip";
import FigmaSyncIcon from "@shared/frontend/FigmaSyncIcon/FigmaSyncIcon";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_INTEREST_GENERAL } from "@shared/segment-event-names";
import { getNoSecondsFormatter } from "@shared/utils/timeAgoFormatters";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import ReactTimeago from "react-timeago";
import DeleteProjectModal from "./DeleteProjectModal";
import ShareModal from "./ShareModal";
import style from "./style.module.css";

function NavBar() {
  const workspace = useWorkspace();
  const figmaSyncErrorMessage = useAtomValue(figmaSyncErrorMessageAtom);
  const isFigmaSyncing = useAtomValue(isFigmaSyncingAtom);
  const figmaSyncAction = useSetAtom(figmaSyncActionAtom);
  const figmaSyncMessage = useAtomValue(figmaSyncMessageAtom);
  const figmaFileUrl = useAtomValue(projectFigmaFileLinkAtom);
  const projectFolder = useAtomValue(projectFolderAtom);
  const { track } = useSegment();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] = useState(false);

  // logic to show the inline editable icon when hovering anywhere in the navbar
  const [hovering, setHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(function addHoverListeners() {
    function handleMouseEnter() {
      setHovering(true);
    }

    function handleMouseLeave() {
      setHovering(false);
    }

    const containerEl = containerRef.current;

    containerEl?.addEventListener("mouseenter", handleMouseEnter);
    containerEl?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      containerEl?.removeEventListener("mouseenter", handleMouseEnter);
      containerEl?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const handleFigmaSyncClick = useCallback(() => {
    figmaSyncAction();
  }, [figmaSyncAction]);

  const handleBetaButtonClick = useCallback(() => {
    window.open(LEARN_MORE_URL.BETA_BUTTON, "_blank");
    track({ event: NS_BETA_INTEREST_GENERAL, properties: { version: "NS" } });
  }, [track]);

  const handleShareButtonClick = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const FigmaSyncLabel = useMemo(() => {
    if (figmaSyncMessage instanceof Date) {
      return (
        <span>
          Synced with Ditto&nbsp;
          <ReactTimeago
            date={figmaSyncMessage}
            key={figmaSyncMessage.toString()}
            formatter={getNoSecondsFormatter("less than a minute ago")}
          />
        </span>
      );
    }
    return figmaSyncMessage;
  }, [figmaSyncMessage]);

  const handleClickDeleteProject = useCallback(() => {
    setIsDeleteProjectModalOpen(true);
  }, []);

  const handleClickOpenInFigma = useCallback(() => {
    if (!figmaFileUrl) return;
    window.open(figmaFileUrl, "_blank");
  }, [figmaFileUrl]);

  const dropdownMenuItems: DropdownMenuItemType[] = useMemo(() => {
    const OpenInFigma: DropdownMenuItemType = {
      type: "option",
      text: "Open in Figma",
      className: style.menuItem,
      onClick: handleClickOpenInFigma,
    };

    const NotifyMeInSlack: DropdownMenuItemType = {
      type: "option",
      text: "Notify me in Slack",
      disabled: true,
      comingSoon: true,
      className: style.comingSoon,
    };

    const DeleteProject: DropdownMenuItemType = {
      type: "option",
      text: "Delete project…",
      className: style.menuItem,
      textColor: "danger",
      onClick: handleClickDeleteProject,
    };

    if (figmaFileUrl) {
      return [OpenInFigma, NotifyMeInSlack, DeleteProject];
    } else {
      return [NotifyMeInSlack, DeleteProject];
    }
  }, [figmaFileUrl, handleClickDeleteProject, handleClickOpenInFigma]);

  const previousCrumbs = useMemo(() => {
    const previousCrumbs = [
      { label: workspace.workspaceInfo.name, value: "/" },
      { label: "Projects", value: "/projects" },
    ];
    if (projectFolder) {
      previousCrumbs.push({ label: projectFolder.name, value: `/folders/${projectFolder._id}` });
    }
    return previousCrumbs;
  }, [projectFolder, workspace]);

  return (
    <div className={style.navBarContainer}>
      <Breadcrumbs
        className={style.breadcrumbs}
        previousCrumbs={previousCrumbs}
        finalCrumb={
          <Suspense fallback={<Skeleton height={17} width={120} baseColor="var(--bg-secondary)" />}>
            <ProjectName hovering={hovering} />
          </Suspense>
        }
        ref={containerRef}
      />
      <div className={style.rightSection}>
        <BetaButton leftText="BETA" rightText="Learn more" hoverRight onClick={handleBetaButtonClick} />
        <div className={style.rightSubgroup}>
          {figmaFileUrl ? (
            <Tooltip forceText={true} side="bottom" type="invert" content={FigmaSyncLabel}>
              <Button
                type="icon"
                level={!!figmaSyncErrorMessage ? "danger" : "secondary"}
                showDot={!!figmaSyncErrorMessage}
                onClick={handleFigmaSyncClick}
              >
                <FigmaSyncIcon isLoading={isFigmaSyncing} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip side="bottom" type="invert" content="Connect to design with the Ditto Figma plugin">
              <Button level="subtle" size="small" style={{ cursor: "default" }}>
                No design connected
              </Button>
            </Tooltip>
          )}

          <Button size="small" level="primary" onClick={handleShareButtonClick}>
            Share
          </Button>
          <DropdownMenu RDropdownMenuContentProps={{ align: "end" }} items={dropdownMenuItems} />
        </div>
      </div>
      <ShareModal open={isShareModalOpen} onOpenChange={setIsShareModalOpen} />
      <DeleteProjectModal open={isDeleteProjectModalOpen} onOpenChange={setIsDeleteProjectModalOpen} />
    </div>
  );
}

function ProjectName(props: { hovering: boolean }) {
  const setProjectName = useSetAtom(editableProjectNameAtom);
  const projectName = useAtomValue(deferredProjectNameAtom);
  const resetProjectName = useSetAtom(resetEditableProjectNameAtom);
  const saveProjectNameAction = useSetAtom(saveProjectNameActionAtom);

  function onReset() {
    resetProjectName();
  }

  return (
    <div className={style.editableNameContainer}>
      <InlineEditableName
        name={projectName}
        placeholder="Give this project a name..."
        onChange={setProjectName}
        onSave={saveProjectNameAction}
        onReset={onReset}
        onBlur={onReset}
        variant="breadcrumb"
        forceHoverState={props.hovering}
        className={style.editableName}
      />
      <Helmet>
        <title>{projectName}</title>
      </Helmet>
    </div>
  );
}

export default NavBar;
