import { navigateToLibraryComponentActionAtom, navigateToLibraryFolderActionAtom } from "@/stores/Library";
import ChangeItem from "@ds/molecules/ChangeItem";
import { CustomChangeItemComponentProps } from "@ds/organisms/ChangeItemList";
import useAtomEffect, { GetterWithPeek } from "@shared/frontend/stores/useAtomEffect";
import { ENTRY_TYPES, extractLibraryComponentIds, isLibraryFolderChangeItem } from "@shared/types/ActualChange";
import { Setter } from "jotai";
import React, { useCallback, useState } from "react";

function LibraryGeneralActivityItem({ changeItem }: CustomChangeItemComponentProps) {
  const [handlers, setHandlers] = useState<{ onClick: () => void } | undefined>();

  const getOnClickFn = useCallback(
    async (_get: GetterWithPeek, set: Setter) => {
      if (
        changeItem.entry_type === ENTRY_TYPES.LIBRARY_COMPONENT_DELETED ||
        changeItem.entry_type === ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_DELETED
      ) {
        setHandlers(undefined);
        return;
      }

      const componentIds = extractLibraryComponentIds(changeItem);
      if (componentIds.length === 1) {
        setHandlers({
          onClick: async () => {
            set(navigateToLibraryComponentActionAtom, componentIds[0]);
          },
        });
        return;
      }

      if (isLibraryFolderChangeItem(changeItem)) {
        const folderId = changeItem.data.folderId;
        setHandlers({
          onClick: () => {
            if (componentIds.length) {
              set(navigateToLibraryFolderActionAtom, folderId, componentIds);
            } else {
              set(navigateToLibraryFolderActionAtom, folderId);
            }
          },
        });
        return;
      }

      setHandlers(undefined);
      return;
    },
    [changeItem]
  );

  useAtomEffect(
    useCallback(
      (get, set) => {
        getOnClickFn(get, set);
      },
      [getOnClickFn]
    )
  );

  return <ChangeItem changeItem={changeItem} onClick={handlers?.onClick} />;
}

export default LibraryGeneralActivityItem;
