import { UNSAFE_useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { UNSAFE_useWorkspace } from "@/store/workspaceContext";
import { useEffect, useRef } from "react";

interface Properties {
  version?: "legacy" | "NS";
  application?: "web_app" | "figma_plugin";
  [key: string]: any;
}

interface TrackArgs {
  event: string;
  properties?: Properties;
}

export type Track = (args: TrackArgs) => void;
export type DebouncedTrack = (args: TrackArgs, debounceTime: number) => void;

// Used to keep track of track calls that are made
// before workspaceContext is fully loaded
let queuedTracks: TrackArgs[] = [];

function useSegment() {
  const authContext = UNSAFE_useAuthenticatedAuth();
  const workspaceContext = UNSAFE_useWorkspace();
  const debounceTimers = useRef<Map<string, NodeJS.Timeout>>(new Map());

  useEffect(
    function tryClearQueue() {
      // Clear track queue
      let queuedTrack = queuedTracks.pop();
      while (queuedTrack) {
        track(queuedTrack);
        queuedTrack = queuedTracks.pop();
      }
    },
    [authContext?.user, workspaceContext?.workspaceInfo]
  );

  function track(args: TrackArgs) {
    if (!authContext || !authContext.user || !workspaceContext || !workspaceContext.workspaceInfo) {
      queuedTracks.push(args);
      return;
    }

    try {
      window.analytics.track(args.event, {
        application: "web_app",
        version: "legacy",
        ...(args.properties || {}),
        user_id: authContext.user.userId,
        workspace_id: authContext.user.workspaceId,
        // This is deprecated and should be removed in the future. Use user_id and workspace_id instead.
        userId: authContext.user.userId,
        workspaceId: authContext.user.workspaceId,
        workspace_plan: workspaceContext.workspaceInfo.plan,
      });
    } catch (e) {
      console.error(e);
    }
  }

  function debouncedTrack(args: TrackArgs, debounceTime: number) {
    const eventKey = args.event; // Use event name as the unique key

    // Clear existing timer for this event
    if (debounceTimers.current.has(eventKey)) {
      clearTimeout(debounceTimers.current.get(eventKey));
    }

    // Set a new timer for the event
    const timer = setTimeout(() => {
      track(args); // Track event when debounce time elapses
      debounceTimers.current.delete(eventKey); // Cleanup timer reference
    }, debounceTime);

    debounceTimers.current.set(eventKey, timer);
  }

  return {
    track,
    debouncedTrack,
  };
}

export default useSegment;
